.asquad-sidebar {
    height: 100%;
  }
  
  .asquad-sidebar .nav-link svg {
    margin-right: 6px;
}

  
  .bottom-nav .nav-link .nav-icon {
    font-size: 1.5rem;
    display:flex;
 justify-content: center;
  }
  
  .nav-link.active {
    font-weight: 500;
    color: grey;
  }
  
  .nav-link.active .nav-icon {
    color: white;

  }
  
  .flex-row.bottom-nav.nav {
    justify-content: center;
    padding-bottom:10px;
}

.asquad-sidebar.card .card-body{
  padding:0px!important;
}



.asquad-sidebar a.active.nav-link {
  margin-right: 10px;
  margin-left: 10px;
  color: white !important;
  background:   #2A9D29 ;
  /* border-left: 2px solid #ade030; */
  border-radius: 12px;
}

.asquad-sidebar  .nav-link {
  margin-right: 10px;
  margin-left: 10px;
  padding-left:2rem;
  margin-top:17px;
  
}


@media screen and (max-width: 600px) { 
a.active.nav-link {
  color: #2a9d29 !important;
}



}


@media screen and (min-width:730px){
  .asquad-sidebar.card .card-body{
    
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.05);
     
  }
}