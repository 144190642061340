.subtask-name {
    max-width: 200px;
}

p.daily-subtask-name {
    font-size:0.8rem;
}

label.form-check-label {
     font-size:0.8rem;
}

.mark-off-section {
    color: #adce18 ;
}

/* CHECK*/

 

.custom-progress-bar {
    position: relative;
    
  }
  
  .custom-progress-bar span {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black; /* Optional: Adjust color if needed */
     /* Optional: Make the text bold */
     font-size:0.6rem;
  }
  