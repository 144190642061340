.wrapper-box {
    background:white;
  border-radius:16px;
    padding:40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    max-width: 929px;
    min-width:929px;
}
.wrapper-group-detail {
    display: flex;
    align-items: center; /* Vertically centers the wrapper-box */
    justify-content: center; /* Optionally horizontally center the wrapper-box */
    height: 80vh; /* Ensure the parent container has a height */
  }
  

.content-group-title {
  font-size:1.5rem;
  font-weight:600;
}

.control-section-group {
    display: flex;
    justify-content: right;
}

.create-btn {
    background:#58ae22;
    padding:10px;
    text-align:center;
    color:white;
    font-size:11px;
    border-radius:14px;
    margin-bottom: 15px;
    cursor:pointer;
}

.current-cycles-btn {
  background:#58ae22;
    padding:10px;
    text-align:center;
    color:white;
    font-size:11px;
    border-radius:14px;
    margin-bottom:16px;
    cursor:pointer;
    
}

.grey-line {
    margin-top:20px;
    margin-bottom:20px;
    height: 1px;
    background: #e9ecdd;
}

.creator-details {
    display: flex;
    align-items:center;
}

p.creator-icon {
    height: 30px;
    width: 30px;
    background:#adce1857;
    border-radius:100px;
    padding-left:0px;
    margin-left:10px;
    display:flex;
    align-items: center;
    justify-content: center;
}
.member-icon{
    height: 30px;
    width: 30px;
    
    border-radius:100px;
    padding-left:0px;
}
p.creator-name {
    padding-left:10px;
    font-size:13px;
}

p.creator-title {
    padding-right:10px;
    font-size:13px;
    font-weight:600;
    width:90px;
    text-align:right;
}
.title-about-group-single p {
    font-weight: 600;
}

.member-names {
    display: flex;
    padding-top:20px;
}
.members-icon-list{
    display:flex;
    flex-wrap:wrap;
}
p.member-title {
    font-size: 13px;
    font-weight: 600;
    width:90px;
}


.grey-line {
    margin-top:20px;
    margin-bottom:20px;
    height: 1px;
    background: #e9ecdd;
}

.add-members-btn {

     background:#58ae22;
    padding:10px;
    text-align:center;
    color:white;
    cursor:pointer;
    font-size:11px;
    border-radius:14px;
}
p.single-group-member-icon {
    height: 30px;
    width: 30px;
    text-align: center;
    background:#adce1857;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    display: flex;
    margin-left: 10px;
}

.delete-section-group-single {
    /* padding-top: 10px; */
    display: flex;
    align-items: center;
    padding-top: 26px;
    justify-content: right;
    cursor:pointer;
    padding-right: 20px;
}

.delete-bin {
    font-size: 23px;
}
.delete-text {
    font-size: 12px;
    padding-left: 8px;
}

@media only screen and (max-width: 760px) {
.page-title-browse{
    padding-top:30px;
    padding-bottom:10px;
}

.page-title-browse-black{
    font-size:16px;
    padding-bottom:20px;
}
.box-section{
    padding-bottom:20px;
}
.wrapper-box{
    max-width:400px;
    min-width:400px;
}
.control-section-group{
    justify-content:left;
}
.button-section{
    width:100%;
}
}