

@media only screen  and (max-width:760px){
    
.image-column img.image{
    padding-left:100px;
    padding-right:100px;
}
.image.book-selected{
    box-shadow:none;
}
}