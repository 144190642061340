.custom-navbar.container {
    max-width:100%;
}
.no-arrow .dropdown-toggle::after {
    display: none !important; /* Use !important to override default styles */
  }
  /* Ensure the dropdown can expand fully */
.no-arrow .dropdown-menu {
    max-height: none !important; /* Remove any max-height */
    overflow: visible !important; /* Ensure items are not clipped */
    z-index: 1050 !important; /* Ensure it is above other elements */
  }
  
  /* Optional: Add some margin to prevent overlap with other UI elements */
  .no-arrow .dropdown-toggle {
    margin-right: 10px; /* Adjust if needed */
  }

  .no-arrow .dropdown-menu {
    left: auto !important;   /* Set left to auto for more control */
    right: 100% !important;  /* Position the dropdown to the left of the trigger */
      /* Ensure it's vertically aligned correctly */
       /* Optional margin to separate the dropdown from the icon */
  }

  .no-arrow .dropdown-menu {
    left: auto !important;
    right: 33% !important;
}

span.dd-username {
    color: #4f4f4f;
    font-size: 12px;

    
    padding-left: 10px;
}
a.dropdown-item{
    font-size:12px;
    font-weight: 600;
    color:#4f4f4f;
}