body {
  font-family: "Open Sans", sans-serif;
  
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
p{
  font-family: "Poppins", sans-serif;
}
html, body {
 
  margin: 0;
  padding: 0;
  background: #adce180a;
  font-family: "Poppins", sans-serif;
}
:root {
  --main-green-color: linear-gradient(45deg, #2A9D29, #ADCE18);
}

/* color edits*/
nav.navbar.navbar-expand-lg.navbar-dark.bg-dark .container {
  background: #272424 !important;
}
.container-fluid {
  height: calc(100vh);
 
}



.loading-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

p.loading-text {
  font-size: 12px;
  color: #14a44d;
  padding-top: 10px;


  font-weight: 600;
  text-align: center;
  width: 200px;
  font-style: italic;
}
.watch-load {
  display: flex;
  justify-content: center;
}

.email-notifications {
  display: flex;
  justify-content: flex-end;
}

p.daily-subtask-name {
  margin:0px;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background:#f7fbee!important;
}


ul.no-bullets {
  list-style-type:none!important ;
}
/*color edits end*/
.editContainer {
  max-width: 1400px;

  padding-top: 5%;
}
.btn {
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 15px;
  padding: 10px 19px;
  cursor: pointer
}
 
.vh-75 {
  height: 75vh;
}


.active {
  font-weight: bold;
  color: #007bff; /* Bootstrap primary color */
}

.signup-wrapper {
  background: white;
}
.customContainer {
 
  padding-top: 5%;
}

button.pay-button.btn.btn-success {
  margin-top: 30px;
}

h5.customTitle {
  text-align: center;
  color: black;
  letter-spacing: 1px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #080808 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #080808 !important;
}

nav {
  --bs-nav-link-hover-color: #bfbfbf !important;
}

.nav-link {
  color: #717175 !important;
}

.modal-title.h4 {
  font-size: 1rem;
}

.cycleWrap {
  padding-top: 70px !important;
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.calendar-modal .modal-dialog {
  max-width:400px;
}

button.align-self-center.btn.btn-primary {
  background: #67b421;
  border-radius: 15px !important;
  border-color: #ffffff;
  color: #f7f7f7;
}
button.align-self-center.btn.btn-primary:hover {
  background:  white;
  border-radius: 5px !important;
  border-color: #302d2d;
  color: black;
}

button.align-self-center.btn.btn-primary:hover{
  background: linear-gradient(45deg, #2A9D29, #ADCE18);
  color:white;
}
.blackBigBtn {
  margin-top: 30px;
}

.form-group.ownerFields label.boldLabel,
.form-group.dateFields label.boldLabel {
  text-align: center !important;
  font-weight: bold;
  padding-bottom: 20px;
}
.form-group.ownerFields label.boldLabel {
  text-align: center !important;
  font-weight: bold;
  padding-top: 30px;
}

.form-group.ownerFields .row .col {
  display: inline-grid;
  grid-template-columns: auto auto;
}

.form-group.dateFields .row .col {
  display: inline-grid;
  grid-template-columns: auto auto;
}
button.btn.btn-dark.plusMember {
  margin-top: 30px;
  background: #4e934e;
  border: 0px solid;
}




.icon-calendar{
  padding-right:10px;
}
p.period-title {
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
  border-bottom: 2px solid #68b422;
  letter-spacing: 0.5px;
  margin-bottom: 32px;
}

.period-section-header {
  display: flex;
  justify-content: center;
}


.username-text {
    padding-left: 10px;
}


p.h-gd-title {
  text-align: center;
  font-weight: 600;
 
  color: #68b422;
  font-family: 'Poppins';
  font-size: 1.1rem;
}


.review-header p {
  text-align: center;
  font-weight: 600;
  color: #68b422;
   letter-spacing:1.2px;
  padding-top: 1.3rem;
  font-size: 1.1rem;
}
.customSubWrap {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
 padding-right:10px;
 padding-left:10px;
  padding-top: 20px;
  border-radius: 12px;
}
.innerContentWrap {
  margin-left: auto;
  max-width: 800px;
  margin-right: auto;
  padding-left: 40px;
  padding-right:40px;
}

input.btn.btn-dark.createCycle {
  display: inline-grid;
  grid-template-columns: auto auto;
  margin-top: 20px; /* background: #4e934e; */
  border: 0px solid;
  width: 100%;
}

.singleOwnerField {
  /* border-radius: 30px; */
  padding: 20px;
  margin-bottom: 10px;
  border-bottom: 2px solid #9393a1;
}

.editContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #212529;
  padding-bottom: 10%;
}
.plusMember {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*might need to change later*/
button.subtask-btn.btn.btn-primary {
  background: #d0d0d0;
  margin-bottom: 10px;
  margin-top: 10px;
  color: white;
  border: 1px solid black;
}

.subtask-div input {
  margin-top: 10px;
}

p.main-goal.title {
  font-size: 20px;
  font-weight: 500;
}

.goal-wrapper {
  padding-top: 20px;
}

/*Signup Form*/
form.FormContainer {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  /* padding-left: 40px; */
  /* padding-right: 40px; */
}

h3.mainTitle {
  text-align: center;
  font-size: 30px;
  margin-bottom: 30px;
}

/* button.btn.btn-primary:hover {
  background: white;
  color: black;
  transition: 0.3s;
  border: 1px solid black;
} */

/* .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #212529;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(40 37 41);
} */
/* .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */
.confirmPassword {
  /* margin-bottom: 30px; */
  padding-bottom: 15px;
}



p.success-message {
  padding-top: 10px;
}
span.navbar-brand img {
  width: 160px;
}
.error-message {
  color: #de204f;
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 20px;
}
.changepwdtxt {
  font-size: 13px;
  font-weight: 600;
  padding-top: 20px;
}
.success-message {
  color: #19c02f;
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 20px;
}

button.join-btn.btn.btn-primary {
  margin-top: 20px;
}

.header-section {
  background: #6ab521;
  padding-top: 30px;
  border-top-right-radius: 14px;
  padding-bottom: 20px;
  color: white;
  border-top-left-radius: 14px;
  /* border-radius: 13px; */
}

@media only screen and (min-width:760px){

  .general-form-wrapper{
    width: 536px;
    border-radius:20px;
   background:white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
 
    margin-left: auto;
    margin-right: auto;
    padding-bottom:30px;
  
  }
  .general-form-wrapper-gd{
    width: 536px;
    border-radius:20px;
   background:white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    
    margin-left: auto;
    margin-right: auto;
   
  
  }

}

p.g-dsc{
  text-align:center;
}

@media only screen and (max-width:761px){
  .general-form-wrapper-gd{
   
    border-radius:20px;
   background:white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    
    margin-left: auto;
    margin-right: auto;
   
  
  }

  .general-form-wrapper{
   
  border-radius:20px;
 background:white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding-bottom:30px;
  margin-left: auto;
  margin-right: auto;
  padding-left:30px;
  padding-right:30px;

}

.header-section h4 {
  font-size:18px;
  padding-left:30px;
  padding-right:30px
}


}
.signup-wrapper {
  max-width: 336px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
}
button.fullwidth.btn.btn-primary {
  border-radius: 5px;
  font-family: sans-serif;
  width: 100%;
  
  background:var(--main-green-color);
  border: 1px solid #302d2d;
  color:white;
}

.googleBtn {
  cursor: pointer;
}

button.fullwidth.btn.btn-primary:hover {
  background: linear-gradient(45deg, #2A9D29, #ADCE18);
  color: black;
  transition: 0.3s;
  border: 1px solid black;
}
form.FormContainer img {
  width: 121px;
  display: block;
  margin: auto;
}
.tagline {
  font-size: 13px;
  text-align: center;
}


.subtask-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.progress-div {
  width: 56%;
}



.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

/*LOGIN*/
/* .FormContainer {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}


.mainTitle {
  text-align: center;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.fullwidth {
  width: 100%;
  margin-top: 20px;
} */

.line-breaker p {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #a09898;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

p span {
  background: #fff;
 
}

input#formBasicPassword2.form-rounded,
input#formBasicPassword.form-rounded,
input#formEmail.form-rounded,
input#formName.form-rounded {
  border-radius: 5px !important;
}

p.table-header-title {
  padding-left: 1rem;
  font-weight: 600;
}



.form-switch .form-check-input:checked[type=checkbox]:after {
 
  background-color: white;
  border: 1px solid #dbf6ca;
 
}



.switch-cycle.col {
  display:flex;
    justify-content:flex-end;
padding-right:10px;
}


.navbar-default {
  margin: 0 !important;
}

.container-wrapper {
  padding-top: 30px;
  height: 100vh !important;
}
.parentWrapper{
  display:flex;
  align-items: center;
   justify-content: center;
  height: 75vh !important;
  
}

/* .card.h-200.custom-style.card:last-of-type {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.card.h-200.custom-style.card:first-of-type {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.card.h-200.custom-style.card {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
} */

/*Overview UIRevamp*/
.custom-style.card:last-of-type {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.custom-style.card:first-of-type {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid #212529;
}

.custom-style.card {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  border: 1px solid #212529;
}

.custom-style.card:hover {
  background:linear-gradient(45deg, #2A9D29, #ADCE18);
  color: white;
  transition: background-color 0.3s;

  /* Changed transition property to 'background-color 3s' */
}

.custom-Button a {
  font-size: 12px;
  color:  black;
  text-decoration: none;
}

.card.h-200.custom-style.card:hover
.custom-Button a
{
    color:white;
}

.custom-Button img {
  margin-right: 5px;
}
.custom-style.card {
  background: #fefefe;
}
.custom-Button a:hover {
  color: #a09898;
}
.custom-style.card:hover {
  box-shadow: none;
}
.custom-style.last-card.card {
  /* background: red; */
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.d-flex.justify-content-center.wrapper-empty {
  height: 120px;
  border-radius: 6px;
  border: 1px solid #302d2d;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

form.cycleForm {
  border: 1px solid #302d2d;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/*Create form*/
.form-group.dateFields {
  text-align: center;

  padding-top: 30px;

  /* justify-content: center!important; */

  /* justify-content: center!important; */
}
.form-group.ownerFields {
  text-align: center;
}
input.mainGoal {
  width: 63%;
  border-radius: 6px;
}
input.subTaskField {
  width: 63%;
  border-radius: 6px;
}

.accordion-flush .accordion-item.active .accordion-header {
  background-color: #302d2d;
  color: white;
}
.accordion-button:not(.collapsed) {
  color: white !important;
  background: linear-gradient(45deg, #2A9D29, #ADCE18);

}
.accordion-button:focus {
  z-index: 3;
  border-color: #302d2d;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}
.accordion-button:focus {
  z-index: 3;
  border-color: #302d2d;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-button:focus {
  border-color: #302d2d !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-item {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}




/* .accordion-button:not(.collapsed) {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
} */

/* .collapsed .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
} */

/* .accordion-button:after {
  background-image: url("
} */
.accordion {
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.form-check-input:checked {
    background-color: #adce18 !important;
    border-color: #adce18 !important;
}

@media screen and (max-width: 600px) {
  .cycleItem h6 {
    font-size: 12px !important;
  }

  .custom-Button a {
    font-size: 8px;
  }
  .innerContentWrap {
    padding-top: 20px;
  }
  h5.customTitle {
    font-size: 13px;
    font-weight: 600;
  }

  .mobile-date .react-datepicker__input-container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-group.ownerFields label.boldLabel,
  .form-group.dateFields label.boldLabel {
    text-align: center !important;
    font-weight: bold;
    padding-bottom: 20px;
    font-size: 12px;
  }
  p.main-goal.title {
    font-size: 14px;
    font-weight: 500;
  }
  label.form-check-label {
    font-size: 12px;
  }

  .daily-subtask-name{
    font-size:12px;
  }
  button.accordion-button {
    font-size: 13px;
  }
}
.row{
  margin:0px;
  --mdb-gutter-x:0px;
}

@media screen and (min-width: 601px) {
  input.rounded-3.custom-datepicker-left {
    float: right;
  }
}

.react-datepicker__input-container input {
  display: block;

  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input.mainGoal {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input.subTaskField {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* .googleBtn {
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.no-bullets p{
  font-size:12px;
}
.googleBtn{
  transition: background-color .3s, box-shadow .3s;
   
 padding: 12px 16px 12px 42px;
 border: none;
 border-radius: 3px;
 box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
 
 color: #757575;
 font-size: 14px;
 font-weight: 500;
 font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
 
 background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
 background-color: white;
 background-repeat: no-repeat;
 background-position: 12px 11px;
 
 &:hover {
   box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
 }
 
 &:active {
   background-color: #eeeeee;
 }
 
 &:focus {
   outline: none;
   box-shadow: 
     0 -1px 0 rgba(0, 0, 0, .04),
     0 2px 4px rgba(0, 0, 0, .25),
     0 0 0 3px #c8dafc;
 }
 
 &:disabled {
   filter: grayscale(100%);
   background-color: #ebebeb;
   box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
   cursor: not-allowed;
 }
}

.googleBtn {
  display:flex;
  margin-bottom:19px;
  /* transition: background-color .3s, box-shadow .3s; */
  padding: 11px 16px 11px 32px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 42px 13px;
  justify-content:center;

}

/* p.divider {
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: center;
} */
span.line-grey {
  height: 1px;
  background: #ced4da;
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
}
.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}



.buttonWrapper {
  display:flex;
justify-content:center;
  padding-top:20px;
  padding-top:20px;
}
.customWidthBtn{
width:230px;
}

 
.FooterWrapper.container{
  max-width:100%;
  background:#ffffff!important;
}
.footer-main{
  color:#000000;
}



ul.footerlinks {
    list-style-type: none!important;
}
.footer-main.row p a {
  color: rgb(0, 0, 0);
}

.lists {
  font-size: 22px;
}
.section-one.container {
  max-width: 100%;
   
  text-align:center;
}

/* ADDED NAV AFFECT */

/* SHIFT */
/* .shift.navbar-nav a:hover {
  color: red!Important;
} */


  

.image-wrap img {
  max-width: 100%;  /* ensures the image is no wider than its container */
  height: auto;     /* maintains the aspect ratio */
  display: block;   /* removes any extra space below the image */
}


@media only screen and (min-width:762px){
 
.mainRow.memberRow.row {
  padding-top: 60px;
}
}
 
@media only screen and (max-width:760px){
  form.form-wrapper .card-title.h5{
    font-size:1rem;
  }
 
  .mainRow.memberRow.row {
    padding-top: 60px;
    padding-left:30px;
    padding-right:30px;
  }
  }

.button-bottom{
  padding-top: 20px;
}

.btn-grp-save{
  margin-right:20px;
}

@media only screen and (min-width:760px){
  .mainrow{
    padding-top:40px;
  }
}

@media only screen and (max-width:762px){
  .mainrow{
    padding-top:40px;
    padding-left:20px;
    padding-right:20px;
  }
}

button.btn.btn-outline-dark.customWidthBtn.btn.btn-primary {
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius:16px;
}
button.btn.btn-outline-dark.customWidthBtn.btn.btn-primary:hover{
color:rgb(245, 245, 245);
background:linear-gradient(45deg, #2A9D29, #ADCE18);
}
.btn-custom-back {
  border: none;
    border-radius: 15px;
    background-color: #302d2d;
}

.btn-custom-save {
  border: none;
  border-radius: 15px;
  background-color: #302d2d;
}
.btn-custom-save:active{
  background-color: #302d2d;
}
.btn-custom-save:hover {
  background-color: #302d2d; /* A vivid green */
  color: #fff; /* White text */
}

.btn-custom-back:hover {
  background-color: #fffefe; /* A light gray */
  color: #000; /* Black text */
}

.progress {
 height: 14px;
 border-radius:14px;
 font-size:10px;

 
}

.accordion {
  --mdb-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
}


input.dropdown-input {
  width: 100%;
  border-radius: 7px;
  border: 1px solid #2a9d29;
}

 
  


.add-sbtask-btn {
  color: #68b422;
  background: #f8fbed;
  border-radius: 14px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100px;
  text-align: center;
  font-size: 12px;
}
  
  button.create-cycle-btn{ 
      border-radius:14px;
      background: linear-gradient(45deg, #2A9D29, #ADCE18);
      color:white;
     
      
  }

    
  button.create-cycle-btn:hover{
    background:rgb(245, 243, 243);
    color:#2f2d2d;
    border-radius:14px;
   
    
}
form.form-wrapper {
  border-radius: 14px;
  padding: 10px;
  box-shadow: 0px 4px 4px 0px #2a9d291c;
  background-color: white;
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
}
.add-goal-btn {
  cursor: pointer;
  border-radius: 12px;
  padding: 10px;
  color: #68b422;
  background: #f8fbed;
  font-size: 12px;
  width:100px;
  text-align:center;
}

.add-goal-btn:hover{
  color:white;
  transition:0.3s;
  background:#adce18;
}
  

form.form-wrapper .card-title.h5 {
  font-weight: 600;
  font-size: 17px;
  color: #2a9d29;
}



form.form-wrapper .card-subtitle.h6 {
  padding-bottom:10px;
  font-weight:600;
  padding-top:10px;
  color:black;
}

/* styles.css or a similar file */
.goal-input {
  background-color: #f8fbed;
  border: 1px solid #6cb62b;
  border-radius: 12px;
}



.navbar-brand img {
  margin-right: var(--mdb-navbar-brand-img-margin-right);
  width: 183px;
}
.btn-primary {
  --mdb-btn-bg:   #2A9D29!important;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px var(--main-green-color);
  --mdb-btn-hover-bg: var(--main-green-color)!important;;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: var(--main-green-color)!important;;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: var(--main-green-color)!important;;
  --mdb-btn-active-color: #fff;
}

.btn-primary {
  --mdb-btn-color:linear-gradient(45deg, #43b241, #ADCE18)!important;
  background: white;
  color:black;
  --mdb-btn-border-color: #302d2d!important;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #302d2d!important;
  --mdb-btn-hover-border-color: #302d2d!important;
  --mdb-btn-focus-shadow-rgb: 88, 134, 210;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #302d2d!important;
  --mdb-btn-active-border-color: #302d2d!important;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(0, 0, 0, 0.15), 0 4px 18px 0 rgba(0, 0, 0, 0.1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #302d2d!important;
  --mdb-btn-disabled-border-color: #302d2d!important;
}
button.btn-custom-save.btn.btn-primary{
  --mdb-btn-color: #fff;
  --mdb-btn-bg: #302d2d!important;
  --mdb-btn-border-color: #302d2d!important;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-hover-bg: #302d2d!important;
  --mdb-btn-hover-border-color: #302d2d!important;
  --mdb-btn-focus-shadow-rgb: 88, 134, 210;
  --mdb-btn-active-color: #fff;
  --mdb-btn-active-bg: #302d2d!important;
  --mdb-btn-active-border-color: #302d2d!important;
  --mdb-btn-active-shadow: 0 8px 9px -4px rgba(0, 0, 0, 0.15), 0 4px 18px 0 rgba(0, 0, 0, 0.1);
  --mdb-btn-disabled-color: #fff;
  --mdb-btn-disabled-bg: #302d2d!important;
  --mdb-btn-disabled-border-color: #302d2d!important;
  --mdb-btn-bg: #302d2d!important;;
  --mdb-btn-color: #fff;
  --mdb-btn-box-shadow: 0 4px 9px -4px #302d2d!important;
  --mdb-btn-hover-bg: #302d2d!important;;
  --mdb-btn-hover-color: #fff;
  --mdb-btn-focus-bg: #302d2d!important;;
  --mdb-btn-focus-color: #fff;
  --mdb-btn-active-bg: #302d2d!important;;
  --mdb-btn-active-color: #fff;
}

.btn-check:focus-visible+.btn-primary, .btn-check:focus+.btn-primary, .btn-primary:focus{
  box-shadow: 0 8px 9px -4px rgb(111 111 111 / 30%), 0 4px 18px 0 rgb(0 0 0 / 20%);
  background-color:#302d2d!important;
}

.create-group-box {
  box-shadow:rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding:20px;
  background:white;
  margin-right:auto;
  margin-left:auto;
  max-width:800px;
  border-radius:16px;
}

.create-group-wrapper {
  padding-top: 40px;
}

button.create-group.btn.btn-primary {
  background: #6eb620;
  border-radius: 16px;
}

.wrapper-row {
  padding-bottom: 30px;
}



.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="rgba(0, 0, 0, 0.5)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/></svg>'); /* Default Bootstrap hamburger icon */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-light .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path stroke="rgba(0, 0, 0, 0.5)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/></svg>');
}
@media only screen and (max-width:762px){
.second-plan{
  padding-top:30px;
}
.plan-section {
  display: flex;
  justify-content: center;
}
}

.first-section p {
  color: black;
  line-height: 3rem;
  font-size: 3rem;
}

button.regiser-btn.btn.btn-primary {
  width:200px;
  text-transform:none;
  background:var(--main-green-color);
  border:1px solid black;
  border-radius:4px;
  font-size:1.2rem;
  font-family:"Maitree";
}
.book-title{
  font-size:2.3rem;
}

.maitree-extralight {
  font-family: "Maitree", serif;
  font-weight: 200;
  font-style: normal;
}

.maitree-light {
  font-family: "Maitree", serif;
  font-weight: 300;
  font-style: normal;
}

.maitree-regular {
  font-family: "Maitree", serif;
  font-weight: 400;
  font-style: normal;
}

.maitree-medium {
  font-family: "Maitree", serif;
  font-weight: 500;
  font-style: normal;
}

.maitree-semibold {
  font-family: "Maitree", serif;
  font-weight: 600;
  font-style: normal;
}

.maitree-bold {
  font-family: "Maitree", serif;
  font-weight: 700;
  font-style: normal;
}

 
.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left:11%;
  padding-right:11%;
}
.conditional-section {
  text-align: center;
}

.card-title.h5 {
  text-align: center;
}

.gd-body {
  padding-top: 20px;
  border-bottom: 1px solid #dadada;
  text-align: center;
  border-top: 1px solid #dadada;
}

.membersection button{
  max-width:120px;
  margin:10px;
}
.membersection {
  display: flex;
  flex-direction: row;   

            
  justify-content: space-between; 
}

.image-column {
  flex: 1 1 22%;
  margin: 10px;
  box-sizing: border-box;
}

.image {
  width: 100%;
  cursor: pointer;
}

.unique-section {
  margin-top: 20px;
  padding: 20px;
  border-top: 1px solid #ccc;
  background-color: #f9f9f9;
}


.books-section .image-column {
  padding: 39px;
}

.image-column img{
 filter: drop-shadow(0 0.2rem 0.8rem rgba(0, 0, 0, 0.2));
}

.books-section.container {
  max-width: 100%;
  --mdb-gutter-x:0;
}

:not(.btn-check)+.btn-primary:hover, .btn-primary:first-child:hover, .btn-primary:focus-visible, .btn-primary:hover {
  border-radius: 14px;
  background: #6ab521;
  box-shadow: 0 8px 9px -4px rgba(59, 113, 202, .3), 0 4px 18px 0 rgba(59, 113, 202, .2);
}

.creator-section {
  text-align: center;
}
.membersection {
  text-align: center;
}

button.btn.btn-primary {
  border-radius: 14px;
  border-radius: 16px;
  background: #6ab521;
}
.unique-section{
  background:linear-gradient(45deg, #2A9D29, #ADCE18);
  padding-top:30px;
  padding-bottom:30px;
  padding-left:30px;
  padding-right:30px;
  color:white;
  text-align:center;
}
.last-section p {
  font-size:2rem;
  line-height:2rem;
  color:white;
}


.sectionFooter2.container{
  background:linear-gradient(45deg, #2A9D29, #ADCE18);
  text-align:center;
  max-width:100%;
  height:24rem;
}
button.regiser-btn-white.btn.btn-primary {
  width:252px;
  color:black;
  background:white;
  border:1px solid black;
  border-radius:4px;
  font-size:1.2rem;
  font-family:"Maitree";
  text-transform:none;
}

.desc-section p {
  max-width: 67%;
}

.desc-section {
  display: flex;
  justify-content: center;
}


.first-section {
  padding-top:6rem;
}
@media only screen and (max-width:760px){

  .first-section {
    padding-top:4rem;
  }
.books-section .image-column{
  padding: 0px;

}
}

p.subtext-login {
  font-weight: 600;
  padding-left: 5px;
  text-decoration: underline;
}

@media only screen and (max-width:760px){


  .input-group>.form-control{
    min-height:20px;
    font-size: 0.8rem;
}

input.goal-input.form-control {
  height: 25px;
}
input.subtask-input.form-control {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 25px;
}
.subtask-input{
  border-radius:12px;
}



button.delete-goal-btn.btn.btn-danger {
  padding-left: 11px;
  height: 25px;
  width: 10px;
  font-size: 0.7rem;
  background: #ff312c;
  padding-top: 5px;
  padding-bottom: 5px;
}
button.delete-sbtask-btn.btn.btn-outline-danger{
  padding-left: 11px;
  height: 25px;
  width: 10px;
  font-size: 0.7rem;
  
  padding-top: 5px;
  padding-bottom: 5px;
}

  .first-section p {
    color: black;
    line-height: 2rem;
    font-size: 2rem;
  }
  button.create-cycle-btn{
    background:linear-gradient(45deg, #2A9D29, #ADCE18);
    font-size:0.7rem;
    border: 0px solid black;
  }
  button.btn-custom-back.btn.btn-primary{
    font-size:0.7rem;
  }
  .innerContentWrap{
    padding:0px;
  }
  .goal-input{
    border:1px solid #37c02f;
    background-color:#ddffe3;
  }
  .add-sbtask-btn {
    padding-top: 3px;
    font-size: 0.7rem;
    padding-bottom: 2px;
    background: #7bbf21;
    margin-bottom: 5px;
    border: 0px;
}
  form.form-wrapper .card-title.h5{
    font-size:1.2rem;
  }


  button.add-goal-btn.btn.btn-primary{
    background:#19c02f;
    color:white;
    font-size:0.7rem;
    padding-top:3px;
    padding-bottom:3px;
  }
  button.regiser-btn.btn.btn-primary{

    
    background: linear-gradient(45deg, #2A9D29, #ADCE18);
    border: 1px solid black;
    border-radius: 4px;
    font-size: 1rem;
    font-family: "Maitree";
    width:178px;
    text-transform:none;
  }
  .book-title{
    font-size:1.5rem;
  }

  .layout-container h1 {
    font-size:1.5rem;
}

.last-section p {
  line-height: 1.5rem;
  font-size: 1.5rem;
}
button.regiser-btn-white.btn.btn-primary{
  width: 170px;
  background:white;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 1rem;
  font-family: "Maitree";
}

.sectionFooter2.container {
  background: linear-gradient(45deg, #2A9D29, #ADCE18);
  text-align: center;
  max-width: 100%;
  height: 17rem;
}

.copyright{
  font-size:0.7rem;
}
}

.image {
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.image.book-selected {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 6px 6px  hsla(119, 59%, 39%, 0.317);
}

.image.selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background:  linear-gradient(45deg, hsla(119, 59%, 39%, 0.317), #ADCE18);
  filter: blur(10px);
}
 
.custom-navbar a.active.nav-link{
  border-bottom :3px solid #ade030;
}
/*FLOAT*/
.floating-save-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  background-color:  #19c02f;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.calendar-icon {
  cursor: pointer; /* Ensure cursor changes to pointer on hover */
  float: right; /* Align icon to the right */
  margin-left: 10px; /* Provide some margin for spacing */
}
.floating-save-btn:hover {
  background-color: black;
}

.floating-save-btn-label {
  position: fixed;
  bottom: 90px;
  right: 20px;
  z-index: 9999;
  background-color: #19c02f;
  color: white;
  border-radius: 4px;
  padding: 5px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

/*endfloat*/

.floating-back-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
  background-color: white;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.floating-back-btn:hover {
  background-color: black;
}
h3.BookGridTitle {
  padding-bottom: 29px;
  font-family:"Maitree";
}
.underline-magical {
  background-image: linear-gradient(120deg, #2A9D29 0%, #ADCE18 100%);

  
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
  &:hover {
    background-size: 100% 88%;
  }
}


.cyclePeriod h6 {
  font-size: 12px;
}

 
.settings-icon {
  /* border: 2px solid #2a9d29; */
  padding: 2px;
  /* background: #f3f3f3; */
  border-radius: 3px;
  padding-left: 0px;
  cursor: pointer;
  /* size: 30px; */
  /* width: 20%; */
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
}

.settings-set {
  display: flex;
  align-items: center; /* Centers items vertically */
  gap: 8px; /* Optional: Adds space between the icon and text */
  cursor:pointer;
}

.settings-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-text {
    font-size:12px;
    color:#68b422;
  margin: 0; /* Removes any default margin from <p> */
}

.review-row {
  padding-left: 2rem;
  padding-right: 2rem;
}



.review-card.card {
  margin-right: 2rem;
}
/* 
.review-goals {
  background: #dbf6ca;
  font-size: 11px;
  border-radius: 12px;
  color: black;
  cursor:pointer;
 
} */

.overall-progress {
  font-size: 12px;
}

/* .sidebar {
  position: sticky;
  top: 0;
  height: 100vh; 
  overflow-y: auto;
} */





.daily-checkbox input.form-check-input {
  margin-left: 0px;
}

.delete-subtask {
   
  background: #ffa1aa;
   
  margin-left: 10px;
  
  color: white;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
  display: flex;
   
}
.review-goals {
  cursor: pointer;
  margin-top: 20px !important;
  display: flex !important;
  justify-content: center !important;
}

.review-button {
  display: inline-block !important;
  padding-left:10px;
  padding-right:10px;
  border-radius:19px;
   padding-top:4px;
   padding-bottom:4px;
   
  font-weight: bold !important;
  text-align: center !important;
  background: #dbf6ca;
  font-size: 9px;
 
  color:#357d45;
  cursor:pointer;
}

.review-card.card {
  margin-bottom: 10px;
   
}


.review-card.card {
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
  /* border: 1px solid #e0e0e0; */
min-width:300px;
  border-radius: 8px;
  transition:transform 0.3s;
  box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05) !important;
}



.review-card.card:hover {
  transform: scale(1.05); /* Scale up the card slightly */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  background-color: #f9f9f9; /* Optional background color change */
}


/* Check */

.review-card-body.card-body {
  padding-top: 0px;
}

.review-card-header.card-header {
  border-bottom:0px;
}

span.review-username {
  font-weight:600;
  color:black;

}

span.last-updated-text {
  font-size:13px;
}

.review-footer {
  padding-top: 17px;
}


span.progress-text {
  color:black;
}

span.percentage-text {
  color: black;
}


/*TRIAL CUSTOM PROGRESS*/
/* ProgressBar.css */
/* ProgressBar.css */
.progress-container {
  position: relative;
  transition: left 0.3s ease;
  height: 8px; /* Height of the progress bar */
  
}

.progress-bar {
  height: 100%; /* Fill the entire height of the container */
  background-color: #eef8ee; /* Light color for the unfilled part */
  border-radius: 5px; /* Rounded edges */
}

.progress-fill {
  position: absolute;
  top:0%;
  height: 100%; /* Fill the entire height of the bar */
  background-color: #2fa72e; /* Color of the filled part */
  border-radius: 5px; /* Rounded edges */
  z-index: 1; /* Ensure it is above the progress bar */

  transition: width 0.5s ease;
}

.progress-circle {
  position: absolute;
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for half the height */
  width: 16px; /* Diameter of the circle */
  height: 16px; /* Diameter of the circle */
  background-color: #2fa72e; /* Color of the circle */
  border-radius: 50%; /* Makes it circular */
  transition: left 0.3s ease; /* Smooth transition */
  border: 2px solid #fff; /* Optional: border for better visibility */
  z-index: 2; /* Ensure circle is above everything */
}



.save-btn-goals {
  font-size: 13px;
  border-radius: 12px;
  background: #6ab521;
  padding: 5px;
  color:white;
}

.input-group>.form-control:focus {
  transition: all .2s linear;
  border-color: #2b9e2a;
  outline: 0;
  box-shadow: inset 0 0 0 1px #2a9d29;
}



input.subtask-input.form-control {

  border-radius:12px;
}


.form-control {
 
  border: 1px solid #2a9d29;
  
}

.add-sbtask-btn:hover {

  background:#6ab521;
  color:white;
}

p.h-gd-title {
  text-align: center;
  font-weight: 600;
  color: #68b422;
  font-family: 'Poppins';
  font-size: 1.1rem;
  letter-spacing: 1px;
   
}

.shared-title.card-title.h5 {
  border-bottom:1px solid green;
  padding-bottom:20px;
  margin-bottom:20px;
}

.mb-3.shared-box.card {
   
  box-shadow:0 2px 15px -3px #2a9d2914, 0 10px 20px -2px #adce1800;
}



.save-btn-goals:hover {
  background: #60a11e;
  border-color: #4a8a17;
  cursor:pointer;
}

.save-btn-goals {
  transition: background 0.3s ease, border-color 0.3s ease;
}

/* :focus-visible {
  outline:  #2a9d29 auto 1px!important;
} */

@media screen and (min-width: 992px) {
 
.sidebar {
  box-shadow: 10px 15px 14px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  height: 100vh; /* Make it span the full viewport height */
  overflow-y: auto; /* Allow sidebar to scroll if needed */
}
}


/* Full-screen loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:linear-gradient(45deg, #43b241, #ADCE18) !important; /* Green background */
  color: white; /* White text */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  z-index: 1000; /* Ensure it covers everything */
}

/* Style for the loading text */
.loading-text {
  text-align: center;
}


/* Ensure html and body cover the full viewport height */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Ensure the root div also takes the full height */
#root, #app { /* Adjust #root or #app to match your root div's ID */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tw-size{
  font-size: 12px;

}

.appleBtn{
display: flex;
    transition: background-color .3s, box-shadow .3s; 
    
    padding: 11px 16px 11px 32px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    background-color: white;
    background-repeat: no-repeat;
    background-position: 42px 13px;
    padding-left:40px;

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
      cursor:pointer;
    }
    
    &:active {
      background-color: #eeeeee;
    }
    
    &:focus {
      outline: none;
      box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
    }
    
    &:disabled {
      filter: grayscale(100%);
      background-color: #ebebeb;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
      cursor: not-allowed;
    }
}
 

 

.apple-icon {
  font-size: 20px; /* Size of the Apple icon */
  margin-right: 10px; /* Space between icon and text */
}

.appleBtn span {
  margin-left: 5px; /* Space between icon and text */
}
