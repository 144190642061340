.mobile-banner {
  background-color: #4fa94d; /* Adjust this color to match your theme */
  color: white;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-banner button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 10px;
  cursor: pointer;
}

.mobile-banner button:hover {
  color: #ffcc00; /* Adjust hover color if needed */
}
