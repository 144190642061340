/* src/components/Layout.css */

.layout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .layout-row {
    display: flex;
    width: 100%;
    margin: 20px 0;
    justify-content: center;
  }
  
  .layout-column {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .heading {
    flex: 1;
    text-align: center;
    font-size: 2rem;
  }
  
  .image {
    flex: 1;
  }
  
  .image img {
    max-width: 100%;
    height: auto;
  }
  
  .layout-column.image.hp img{
    height:70%;
 
}

