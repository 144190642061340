/* PrivacyPolicy.css */
.privacy-policy-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.privacy-policy-container h1 {
  text-align: center;
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.privacy-policy-container p {
  line-height: 1.6;
  color: #555;
  font-size: 1rem;
  margin-bottom: 20px;
}

.privacy-policy-container h2 {
  color: #333;
  font-size: 1.8rem;
  margin-top: 40px;
}

.privacy-policy-container ul {
  margin-left: 20px;
  list-style-type: disc;
  color: #555;
}

.privacy-policy-container ul li {
  margin-bottom: 10px;
}

.privacy-policy-container a {
  color: #0073e6;
  text-decoration: none;
}

.privacy-policy-container a:hover {
  text-decoration: underline;
}

.privacy-policy-footer {
  text-align: center;
  font-size: 0.9rem;
  color: #888;
  margin-top: 40px;
}
