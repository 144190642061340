.greeting-section {
    /* border: 1px solid; */
    padding-right: 2rem;
    padding-left: 3rem;
    padding-top: 2rem;
}

.dashboard-header-section{
    
  /* border: 1px solid; */
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 2rem;

}

.sc-kAycRU.hfBvEA.rdt_TableHead {
    color: #B5B7C0;
    font-weight: 400;
    font-size: 1rem;
}


/* styles.css */



.status-completed {
    color: green;
    border-radius: 26px;
    background: #dbf6ca;
    padding: 10px;
    width:121px;
    padding-left: 20px;
    text-align:center;
    padding-right: 20px;
}

.status-completed {
    color: green;
     border-radius: 26px;
     background: #dbf6ca;
     padding: 3px;
     padding-left: 0px;
     width: 105px;
     font-size: 10px;
     text-align: center;
     padding-right: 0px;
 }

a.review-link {
    color: #71b71f;
}
  

.status-in-progress {
    color: orange;
    border-radius: 26px;
    background: #ffa6004f;
    padding: 3px;
    padding-left: 0px;
    width: 105px;
    font-size: 10px;
    text-align: center;
    padding-right: 0px;
}
  
 


.status-incomplete {
   color:red;
    border-radius: 26px;
    background: rgba(255, 0, 0, 0.455) ;
    padding: 3px;
    padding-left: 0px;
    width: 105px;
    font-size: 10px;
    text-align: center;
    padding-right: 0px;
}

p.dh-stat-text {
    display: none;
}

div .rdt_TableRow {
    color:Black;
    font-size:0.8rem;
    
}

.dashboard-header-content {
    background: #FDFDFD;
    padding-top: 1.3rem;
    border-radius: 16px;
    padding-bottom: 1.3rem;
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.dh-header-img img {
    width: 109px;
}

.dh-header-img {
    float: right;
    padding-right: 10px;
}

.dh-header-title p {
    font-size: 1.3rem;
    color: #B5B7C0;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.dh-total {
    font-size: 2rem;
    padding-top: 0px;
    color: black;
}
span.dh-stat-number {
    color: #adce18;
}


/* TABLE CSS*/

.table-section{
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 2rem;

}
.table-content {
    background: #FDFDFD;
    padding-top: 1.3rem;
    border-radius: 16px;
    padding-bottom: 1.3rem;
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;

}

.rdt_TableHead {
    font-size: 0.9rem;
    color: #757575;
}




  @media screen and (max-width: 600px) {
    .dashboard-header-content {
        background: transparent;
        box-shadow: none;
      }

      .dashboard-header-section{
    
        /* border: 1px solid; */
        
        padding-top: 0;
      
      }

      .dh-header-img img {
        /* width: 30px; */
        /* height: 30px; */
        height: 70px;
        width: 70px;
        /* padding-left: 40px; */
        /* float: left; */
    }

    .dh-header-title p {
        font-size: 18px;
    }

    .dh-total{
        font-size:2rem;
        padding-top:0px;

    }
    .ds-col .row {
        padding-top: 10px;
        
        padding-bottom: 13px;
    }


    .dh-header-title p {
        font-size: 18px;
    }

      .ds-col{
        background:white;
        margin-bottom:10px;
        border-radius:12px;
    }

    .image-wrapper.col {
        align-items: center;
        justify-content: center;
        display: flex;
    }
  }