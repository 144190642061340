.group-card {
  min-height: 7.4rem;
  transition: transform 0.2s, box-shadow 0.2s;
  background: white;
 
}
  /* .group-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    background:linear-gradient(45deg, #2A9D29, #ADCE18);
 
    color:rgb(255, 255, 255);
  } */

  


.header-wrapper {
  background: #58ae22;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  
  color:white;
}

.text-member {
  padding-top: 0px;
  font-size: 8px;
}

.member-no {
  font-size: 12px;
}

button.view-details.btn.btn-primary {
  background: #dbf6ca;
}


.member-no,
.text-member {
  margin: 0; /* Ensure no margin is applied */
  padding: 0; /* Ensure no padding is applied */
}


.group-overview-section {
  padding-top:20px;
}


/**TEST**/



.member-ov-section.row {
  display: flex;
  align-items: center;
  padding-bottom:20px;
}

.content-section {
  padding-left: 2rem;
}

.group-card {
   
  width: 100%;
  margin-right: 2rem;
  /* width: 80%; */
  box-shadow:0 1px 3px #0000001a, 0 1px 2px #0000000f;
  border-radius: 18px;
}

.member-icon.col {
  display:flex;
   
  justify-content: flex-end;
  font-size: 1.7rem;
  padding-right:10px;
}




.description-content {
  width: 12rem;
  height:52px;
}

.description-content {
  text-align: center;
}
.description-section {
  padding-top: 30px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  /* text-align: center; */
}
  
  .group-card:hover .view-details{
    
        border: none;
        border-radius: 15px;
        background-color: #ffffff;
        color:#302d2d;
    
  }
  .view-details {
    border: none;
    border-radius: 15px;
    font-size: 0.7rem;
    background-color: #ffffff;
}

@media only screen and (min-width: 760px) {
  .sidebar {
    height: 100vh; /* Sets the height of the sidebar to 100% of the viewport height */
    overflow-y: auto; /* Adds scroll to sidebar if content overflows */
  
}

.sidebar .card {
  box-shadow:0 4px 12px 0 rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.05);
  background: #f7fbee;
    height: 100%; /* Ensures the card stretches to fill the sidebar */
    border-radius: 0; /* Optional: removes border radius for a full-height look */
}

.card-title.h5 {
  font-size: 1rem;
}

h3.page-title-browse {
    padding-top: 1.8rem;
  color:#2A9D29;
  font-size:1.5rem;
}

h3.page-title-browse-groups {
  
  padding-top: 1.8rem;

  color:#2A9D29;
  font-size:1.5rem;
}


.box-section {

  padding-bottom:1.5rem;
}

.page-title-browse-black{
  
  padding-top: 1.2rem;
  color:#000000;
  font-size:1.2rem;
  padding-bottom:1.2rem;
}
h3.page-title-browse-groups.mobile-view{
  padding-top:30px;
  padding-bottom:30px;
}


}

@media only screen and (max-width: 760px) {
  .card-title.h5 {
    font-size: 0.7rem;
}

h3.page-title-browse-groups.mobile-view {
  font-size: 1.3rem;
  padding-top: 30px;
  padding-bottom: 20px;
}
.wrapper-box{
  width:200px;
}
}