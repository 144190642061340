.custom-calendar {
    max-width: 400px; /* Adjust width as needed */
    font-family: Arial, sans-serif; /* Specify your preferred font */
  }
  
  .selected {
    position: relative;
    background-color: #adce18; /* Background color for selected dates */
    color: white; /* Text color */
    text-decoration: line-through; /* Cross out text */
   /* Prevent clicking on crossed-out dates */
  }
  
  .react-calendar__tile--active {
    background: #adce188c!important;
    color: white;
}

.custom-close-button {
   
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color:#6ab521a6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color:white;
  cursor:pointer;
}


.custom-close-button:hover {
   transition:0.3s;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color:#6bb521;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color:white;
  cursor:pointer;
}

.modal-header{
  color:white;
  background:#6ab521;
}

button.save-dates.btn.btn-primary {
  font-size: 13px;
  padding: 10px;
  border-radius: 50px;
  color: white;
  margin-top: 10px;
  float: right;
  margin-right: 10px;
}

.custom-close-button svg {
  color: white;
}

.custom-close-button:focus {
  outline: none;
  box-shadow: none;
}